<template>
  <div class="tianfulove">
    <div class="leftBox">
      <el-menu
        :default-active="defaultActive"
        class="el-menu-vertical-demo"
        @select="handleSelect"
        @open="handleOpen"
        @close="handleClose"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item
          :index="item.name"
          v-for="item in menuList"
          :key="item.name"
        >
          <i class="el-icon-menu"></i>
          <span slot="title">{{ item.meta.name }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="rightBox">
      <router-view />
    </div>
  </div>
</template>

<script>
import router from "@/router/index.js";

export default {
  components: {},
  data() {
    return {
      defaultActive: "user",
      menuList: [],
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleSelect(name) {
      this.$router.push({
        name,
      });
    },
  },
  mounted() {
    this.menuList = router.getRoutes().filter((e) => e.meta.isMenu);
    this.defaultActive = this.$route.name;
  },
};
</script>
